<template>
  <router-view ref="child" />
</template>
<script>
import socket from './utils/socket'
import * as Service from "./api/index";
export default {
  data() {
    return {};
  },
  created() {
    this.getSocketUrl()
  },
  mounted() {
    let that = this
    const role = localStorage.getItem("vce-token-service");
    if (role) {
      socket.initWebpack()
      if(!localStorage.getItem('vce-service_id')) {
        that.getServiceId()
      }
    }
  },
  methods: {
    async getServiceId () {
      const res = await Service.getServiceIndex();
      console.log('getServiceId',res)
      localStorage.setItem('vce-service_id',res.data.userinfo.user_id)
    },
    async getSocketUrl () {
      const res = await Service.getSocketUrl();
      localStorage.setItem('socketUrl', res.data.socketUrl)
    }
  }
};
</script>
<style>
body {
  margin: 0;
  padding: 0;
}
</style>

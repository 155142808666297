<template>
  <div class="plate_title">
    <div class="plate_title_name">{{title}}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  /* padding: 18px 0; */
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding-right: 25px;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}
</style>

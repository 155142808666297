const emojiList = [
  {
    value: '[大笑]',
    regexp: '\\[大笑\\]',
    url: require("@/assets/emoji/emoji_00.png"),
  },
  {
    value: '[可爱]',
    regexp: '\\[可爱\\]',
    url: require("@/assets/emoji/emoji_01.png"),
  },
  {
    value: '[色]',
    regexp: '\\[色\\]',
    url: require("@/assets/emoji/emoji_02.png"),
  },
  {
    value: '[嘘]',
    regexp: '\\[嘘\\]',
    url: require("@/assets/emoji/emoji_03.png"),
  },
  {
    value: '[亲]',
    regexp: '\\[亲\\]',
    url: require("@/assets/emoji/emoji_04.png"),
  },
  {
    value: '[呆]',
    regexp: '\\[呆\\]',
    url: require("@/assets/emoji/emoji_05.png"),
  },
  {
    value: '[口水]',
    regexp: '\\[口水\\]',
    url: require("@/assets/emoji/emoji_06.png"),
  },
  {
    value: '[呲牙]',
    regexp: '\\[呲牙\\]',
    url: require("@/assets/emoji/emoji_07.png"),
  },
  {
    value: '[鬼脸]',
    regexp: '\\[鬼脸\\]',
    url: require("@/assets/emoji/emoji_08.png"),
  },
  {
    value: '[害羞]',
    regexp: '\\[害羞\\]',
    url: require("@/assets/emoji/emoji_09.png"),
  },
  {
    value: '[偷笑]',
    regexp: '\\[偷笑\\]',
    url: require("@/assets/emoji/emoji_10.png"),
  },
  {
    value: '[调皮]',
    regexp: '\\[调皮\\]',
    url: require("@/assets/emoji/emoji_11.png"),
  },
  {
    value: '[可怜]',
    regexp: '\\[可怜\\]',
    url: require("@/assets/emoji/emoji_12.png"),
  },
  {
    value: '[敲]',
    regexp: '\\[敲\\]',
    url: require("@/assets/emoji/emoji_13.png"),
  },
  {
    value: '[惊讶]',
    regexp: '\\[惊讶\\]',
    url: require("@/assets/emoji/emoji_14.png"),
  },
  {
    value: '[流感]',
    regexp: '\\[流感\\]',
    url: require("@/assets/emoji/emoji_15.png"),
  },
  {
    value: '[委屈]',
    regexp: '\\[委屈\\]',
    url: require("@/assets/emoji/emoji_16.png"),
  },
  {
    value: '[流泪]',
    regexp: '\\[流泪\\]',
    url: require("@/assets/emoji/emoji_17.png"),
  },
  {
    value: '[嚎哭]',
    regexp: '\\[嚎哭\\]',
    url: require("@/assets/emoji/emoji_18.png"),
  },
  {
    value: '[惊恐]',
    regexp: '\\[惊恐\\]',
    url: require("@/assets/emoji/emoji_19.png"),
  },
  {
    value: '[怒]',
    regexp: '\\[怒\\]',
    url: require("@/assets/emoji/emoji_20.png"),
  },
  {
    value: '[酷]',
    regexp: '\\[酷\\]',
    url: require("@/assets/emoji/emoji_21.png"),
  },
  {
    value: '[不说]',
    regexp: '\\[不说\\]',
    url: require("@/assets/emoji/emoji_22.png"),
  },
  {
    value: '[鄙视]',
    regexp: '\\[鄙视\\]',
    url: require("@/assets/emoji/emoji_23.png"),
  },
  {
    value: '[阿弥陀佛]',
    regexp: '\\[阿弥陀佛\\]',
    url: require("@/assets/emoji/emoji_24.png"),
  },
  {
    value: '[奸笑]',
    regexp: '\\[奸笑\\]',
    url: require("@/assets/emoji/emoji_25.png"),
  },
  {
    value: '[睡着]',
    regexp: '\\[睡着\\]',
    url: require("@/assets/emoji/emoji_26.png"),
  },
  {
    value: '[口罩]',
    regexp: '\\[口罩\\]',
    url: require("@/assets/emoji/emoji_27.png"),
  },
  {
    value: '[努力]',
    regexp: '\\[努力\\]',
    url: require("@/assets/emoji/emoji_28.png"),
  },
  {
    value: '[抠鼻孔]',
    regexp: '\\[抠鼻孔\\]',
    url: require("@/assets/emoji/emoji_29.png"),
  },
  {
    value: '[疑问]',
    regexp: '\\[疑问\\]',
    url: require("@/assets/emoji/emoji_30.png"),
  },
  {
    value: '[怒骂]',
    regexp: '\\[怒骂\\]',
    url: require("@/assets/emoji/emoji_31.png"),
  },
  {
    value: '[晕]',
    regexp: '\\[晕\\]',
    url: require("@/assets/emoji/emoji_32.png"),
  },
  {
    value: '[呕吐]',
    regexp: '\\[呕吐\\]',
    url: require("@/assets/emoji/emoji_33.png"),
  },
  {
    value: '[强]',
    regexp: '\\[强\\]',
    url: require("@/assets/emoji/emoji_34.png"),
  },
  {
    value: '[弱]',
    regexp: '\\[弱\\]',
    url: require("@/assets/emoji/emoji_35.png"),
  },
  {
    value: '[OK]',
    regexp: '\\[OK\\]',
    url: require("@/assets/emoji/emoji_36.png"),
  },
  {
    value: '[拳头]',
    regexp: '\\[拳头\\]',
    url: require("@/assets/emoji/emoji_37.png"),
  },
  {
    value: '[胜利]',
    regexp: '\\[胜利\\]',
    url: require("@/assets/emoji/emoji_38.png"),
  },
  {
    value: '[鼓掌]',
    regexp: '\\[鼓掌\\]',
    url: require("@/assets/emoji/emoji_39.png"),
  },
  {
    value: '[发怒]',
    regexp: '\\[发怒\\]',
    url: require("@/assets/emoji/emoji_40.png"),
  },
  {
    value: '[骷髅]',
    regexp: '\\[骷髅\\]',
    url: require("@/assets/emoji/emoji_41.png"),
  },
  {
    value: '[便便]',
    regexp: '\\[便便\\]',
    url: require("@/assets/emoji/emoji_42.png"),
  },
  {
    value: '[火]',
    regexp: '\\[火\\]',
    url: require("@/assets/emoji/emoji_43.png"),
  },
  {
    value: '[溜]',
    regexp: '\\[溜\\]',
    url: require("@/assets/emoji/emoji_44.png"),
  },
  {
    value: '[爱心]',
    regexp: '\\[爱心\\]',
    url: require("@/assets/emoji/emoji_45.png"),
  },
  {
    value: '[心碎]',
    regexp: '\\[心碎\\]',
    url: require("@/assets/emoji/emoji_46.png"),
  },
  {
    value: '[钟情]',
    regexp: '\\[钟情\\]',
    url: require("@/assets/emoji/emoji_47.png"),
  },
  {
    value: '[唇]',
    regexp: '\\[唇\\]',
    url: require("@/assets/emoji/emoji_48.png"),
  },
  {
    value: '[戒指]',
    regexp: '\\[戒指\\]',
    url: require("@/assets/emoji/emoji_49.png"),
  },
  {
    value: '[钻石]',
    regexp: '\\[钻石\\]',
    url: require("@/assets/emoji/emoji_50.png"),
  },
  {
    value: '[太阳]',
    regexp: '\\[太阳\\]',
    url: require("@/assets/emoji/emoji_51.png"),
  },
  {
    value: '[有时晴]',
    regexp: '\\[有时晴\\]',
    url: require("@/assets/emoji/emoji_52.png"),
  },
  {
    value: '[多云]',
    regexp: '\\[多云\\]',
    url: require("@/assets/emoji/emoji_53.png"),
  },
  {
    value: '[雷]',
    regexp: '\\[雷\\]',
    url: require("@/assets/emoji/emoji_54.png"),
  },
  {
    value: '[雨]',
    regexp: '\\[雨\\]',
    url: require("@/assets/emoji/emoji_55.png"),
  },
  {
    value: '[雪花]',
    regexp: '\\[雪花\\]',
    url: require("@/assets/emoji/emoji_56.png"),
  },
  {
    value: '[汗]',
    regexp: '\\[汗\\]',
    url: require("@/assets/emoji/emoji_145.png"),
  },
  {
    value: '[拜一拜]',
    regexp: '\\[拜一拜\\]',
    url: require("@/assets/emoji/emoji_160.png"),
  },
  {
    value: '[惊喜]',
    regexp: '\\[惊喜\\]',
    url: require("@/assets/emoji/emoji_161.png"),
  },
  {
    value: '[流汗]',
    regexp: '\\[流汗\\]',
    url: require("@/assets/emoji/emoji_162.png"),
  },
  {
    value: '[卖萌]',
    regexp: '\\[卖萌\\]',
    url: require("@/assets/emoji/emoji_163.png"),
  },
  {
    value: '[默契眨眼]',
    regexp: '\\[默契眨眼\\]',
    url: require("@/assets/emoji/emoji_164.png"),
  },
  {
    value: '[烧香拜佛]',
    regexp: '\\[烧香拜佛\\]',
    url: require("@/assets/emoji/emoji_165.png"),
  },
  {
    value: '[晚安]',
    regexp: '\\[晚安\\]',
    url: require("@/assets/emoji/emoji_166.png"),
  },
  {
    value: '[握手]',
    regexp: '\\[握手\\]',
    url: require("@/assets/emoji/emoji_167.png"),
  }
]
export default emojiList
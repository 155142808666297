import request from "../utils/request";
// 获取聊天记录
export const getChatList = (query) => {
  return request({
    url: "/service/info/getChatList",
    method: "POST",
    data: query,
  });
};
// 获取基础信息
export const getBaseInfo = (query) => {
  return request({
    url: "/service/statistics/getBaseInfo",
    method: "POST",
    data: query,
  });
};
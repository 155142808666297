import request from "../utils/request";
//获取客户列表
export const getUserList = (query) => {
  return request({
    url: "/service/work_weixin/getUserList",
    method: "POST",
    data: query,
  });
};
//获取应用列表
export const getAppList = (query) => {
  return request({
    url: "/service/work_weixin/getAppList",
    method: "POST",
    data: query,
  });
};
//获取企业微信-用户-聊天记录
export const getUserChatList = (query) => {
  return request({
    url: "/service/work_weixin/getUserChatList",
    method: "POST",
    data: query,
  });
};
//给客户发消息
export const sendUserMessage = (query) => {
  return request({
    url: "/service/work_weixin/sendUserMessage",
    method: "POST",
    data: query,
  });
};
//获取企业微信-应用-消息记录
export const getAppChatList = (query) => {
  return request({
    url: "/service/work_weixin/getAppChatList",
    method: "POST",
    data: query,
  });
};
//给客户发应用消息
export const sendAppMessage = (query) => {
  return request({
    url: "/service/work_weixin/sendAppMessage",
    method: "POST",
    data: query,
  });
};
//获取分享图片
export const getWorkWeixinPoster = (query) => {
  return request({
    url: "/service/work_weixin/getWorkWeixinPoster",
    method: "POST",
    data: query,
  });
};
//生成企业微信海报
export const createWorkWeixinPoster = (query) => {
  return request({
    url: "/service/work_weixin/createWorkWeixinPoster",
    method: "POST",
    data: query,
  });
};

import request from "../utils/request";
// 获取圈子数据统计
export const getCircleStatistics = (query) => {
  return request({
    url: "/service/statistics/getCircleStatistics",
    method: "POST",
    data: query,
  });
};
// 获取socket连接人数峰值
export const getSocketStatistics = (query) => {
  return request({
    url: "/service/statistics/getSocketStatistics",
    method: "POST",
    data: query,
  });
};
// 获取会员分布数据
export const getUserStatistics = (query) => {
  return request({
    url: "/service/statistics/getUserStatistics",
    method: "POST",
    data: query,
  });
};
// 获取客服数据统计
export const getServiceStatistics = (query) => {
  return request({
    url: "/service/statistics/getServiceStatistics",
    method: "POST",
    data: query,
  });
};
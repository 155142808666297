import request from "../utils/request";

//客户经理列表
export const getServiceList = (query) => {
  return request({
    url: "/service/manage/getServiceList",
    method: "POST",
    data: query,
  });
};
//客服详情
export const getServiceInfo = (query) => {
  return request({
    url: "/service/manage/getServiceInfo",
    method: "POST",
    data: query,
  });
};
//修改客服详情
export const editServiceInfo = (query) => {
  return request({
    url: "/service/manage/editServiceInfo",
    method: "POST",
    data: query,
  });
};
// 获取客服基础配置
export const getServiceBaseInfo = (query) => {
  return request({
    url: "/service/manage/getServiceBaseInfo",
    method: "POST",
    data: query,
  });
};
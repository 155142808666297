import request from "../utils/request";
// 获取统计数据
export const touristStatistics = (query) => {
  return request({
    url: "/service/statistics/touristStatistics",
    method: "POST",
    data: query,
  });
};
// 获取基础信息
export const getBaseInfo = (query) => {
  return request({
    url: "/service/statistics/getBaseInfo",
    method: "POST",
    data: query,
  });
};
// 邀请有奖
export const getUserList = (query) => {
  return request({
    url: "/service/statistics/getUserList",
    method: "POST",
    data: query,
  });
};
// 邀请有奖排行
export const recommendRank = (query) => {
  return request({
    url: "/service/statistics/recommendRank",
    method: "POST",
    data: query,
  });
};
// 获取客服基础配置
export const getServiceBaseInfo = (query) => {
  return request({
    url: "/service/manage/getServiceBaseInfo",
    method: "POST",
    data: query,
  });
};